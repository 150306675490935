import { BlobServiceClient } from '@azure/storage-blob';
import { FileWithPath } from 'react-dropzone';

const AZURE_BLOB_STORAGE_SUFFIX = 'blob.core.windows.net';

const parseContainerName = (pathname: string) =>
  pathname.substring(pathname.indexOf('/') + 1, pathname.indexOf('/', 2));

const parseFileName = (pathname: string) => pathname.substring(pathname.indexOf('/', 2) + 1);

export const uploadToAzureBlobStorage = (
  writeUrl: string,
  file: FileWithPath,
  onProgress?: (progress: number) => void,
) => {
  const url = new URL(writeUrl);

  const accountUrl = `${url.protocol}//${url.host}`; // Example: https://uniquedevfgpt.blob.core.windows.net
  const sasToken = url.search; // Example: ?sv=2021-10-04&spr=https&st=2023-05-30T19%3A12%3A38Z&se=2023-05-30T19%3A32%3A38Z&sr=b&sp=w&sig=blablablabla
  const containerName = parseContainerName(url.pathname); // Example: ingestion
  const fileName = parseFileName(url.pathname);

  return new BlobServiceClient(`${accountUrl}${sasToken}`)
    .getContainerClient(containerName)
    .getBlockBlobClient(fileName)
    .uploadData(file, {
      onProgress: (progressEvent) =>
        onProgress?.(Math.round((progressEvent.loadedBytes * 100) / file.size)),
      blobHTTPHeaders: { blobContentType: file.type },
    });
};

export async function uploadToCustomStorage(url: string, file: FileWithPath) {
  const resp = await fetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': file.type },
    body: file,
  });
  if (resp && !resp.ok) {
    throw new Error(`Failed to upload file to custom storage: ${file.name}`);
  }
  return resp;
}

export async function uploadToStorage({
  content,
  files,
  setFileProgress,
  upsertContentAttributeName,
}: {
  // FIXME: define type ContentUpsertMutation from graphql
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
  files: FileWithPath[];
  setFileProgress: (fileName: string, progress: number) => void;
  upsertContentAttributeName: string;
}) {
  // FIXME: Type this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const uploads = content.map(async (contentRecord: any, index: number) => {
    const uploadUrl = new URL(contentRecord[upsertContentAttributeName].writeUrl);
    const uploadResult = uploadUrl.hostname.endsWith(AZURE_BLOB_STORAGE_SUFFIX)
      ? await uploadToAzureBlobStorage(
          contentRecord[upsertContentAttributeName].writeUrl,
          files[index],
          (progress) => setFileProgress(files[index].name, progress),
        )
      : await uploadToCustomStorage(
          contentRecord[upsertContentAttributeName].writeUrl,
          files[index],
        );

    // When uploading is done we set fileProgress to 100 in case of no updates from uploadTo function
    setFileProgress(files[index].name, 100);

    return {
      content: contentRecord,
      byteSize: files[index].size,
      uploadResult,
    };
  });
  return Promise.allSettled(uploads);
}
