import { FC } from 'react';
import { IconProps } from '.';

export const IconUploadArrow: FC<IconProps> = ({
  width = '20px',
  height = '20px',
  className = 'text-on-primary',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75 22.0003C1.99375 22.0003 1.34635 21.7352 0.807813 21.205C0.269271 20.6749 0 20.0375 0 19.293V16.5857C0 16.2021 0.131771 15.8806 0.395313 15.6212C0.658854 15.3617 0.985417 15.232 1.375 15.232C1.76458 15.232 2.09115 15.3617 2.35469 15.6212C2.61823 15.8806 2.75 16.2021 2.75 16.5857V19.293H19.25V16.5857C19.25 16.2021 19.3818 15.8806 19.6453 15.6212C19.9089 15.3617 20.2354 15.232 20.625 15.232C21.0146 15.232 21.3411 15.3617 21.6047 15.6212C21.8682 15.8806 22 16.2021 22 16.5857V19.293C22 20.0375 21.7307 20.6749 21.1922 21.205C20.6536 21.7352 20.0063 22.0003 19.25 22.0003H2.75ZM9.625 5.5533L7.04688 8.09142C6.77188 8.36215 6.44531 8.49188 6.06719 8.4806C5.68906 8.46932 5.3625 8.32831 5.0875 8.05758C4.83542 7.78684 4.70365 7.47099 4.69219 7.11001C4.68073 6.74903 4.8125 6.43318 5.0875 6.16244L10.0375 1.28925C10.175 1.15388 10.324 1.058 10.4844 1.0016C10.6448 0.945194 10.8167 0.916992 11 0.916992C11.1833 0.916992 11.3552 0.945194 11.5156 1.0016C11.676 1.058 11.825 1.15388 11.9625 1.28925L16.9125 6.16244C17.1875 6.43318 17.3193 6.74903 17.3078 7.11001C17.2964 7.47099 17.1646 7.78684 16.9125 8.05758C16.6375 8.32831 16.3109 8.46932 15.9328 8.4806C15.5547 8.49188 15.2281 8.36215 14.9531 8.09142L12.375 5.5533V15.232C12.375 15.6155 12.2432 15.937 11.9797 16.1965C11.7161 16.4559 11.3896 16.5857 11 16.5857C10.6104 16.5857 10.2839 16.4559 10.0203 16.1965C9.75677 15.937 9.625 15.6155 9.625 15.232V5.5533Z"
        fill="currentColor"
        className={className}
      />
    </svg>
  );
};
