import { useWindowResize } from '@unique/shared-library';
import cn from 'classnames';
import React, {
  FocusEvent,
  HTMLProps,
  KeyboardEventHandler,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  FieldValues,
  Path,
  UseFormRegister,
  UseFormRegisterReturn,
} from 'react-hook-form/dist/types';
import { truncatePlaceholderText } from '../helpers/truncatePlaceholderText';
import { Tooltip } from './Tooltip';
import { usePrevious } from './helpers';

type TextareaProps<TFieldValues extends FieldValues> = {
  className?: string;
  name: Path<TFieldValues>;
  isColor?: boolean;
  label?: string;
  description?: string;
  errorMessage?: string;
  tooltipContent?: ReactNode;
  labelClassname?: string;
  initialHeight?: number;
  maxHeight?: number;
  maxLengthWarning?: string;
  register?: UseFormRegister<TFieldValues>;
  handleTextAreaResize?: (height: number) => void;
  handleTextAreaChange?: (value: string) => void;
  setRef?: (ref: Ref<HTMLTextAreaElement>) => void;
} & HTMLProps<HTMLTextAreaElement>;

type RegisterType<TFieldValues extends FieldValues> = Partial<
  UseFormRegisterReturn<Path<TFieldValues>>
>;

export const Textarea = <TFieldValues extends FieldValues>({
  className,
  name,
  label,
  description,
  errorMessage,
  tooltipContent,
  labelClassname,
  initialHeight = 40,
  maxHeight,
  maxLengthWarning,
  register,
  handleTextAreaResize,
  handleTextAreaChange,
  setRef,
  isColor,
  ...htmlProps
}: TextareaProps<TFieldValues>) => {
  const [value, setValue] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { disabled, required, maxLength, placeholder, readOnly } = htmlProps;
  const { height, width } = useWindowResize();

  const { ref: registerRef, ...registerRest }: RegisterType<TFieldValues> = register
    ? register(name, {
        required: { value: !!required, message: 'This field is required' },
      })
    : { ref: undefined };

  useEffect(() => {
    if (setRef) setRef(textareaRef);
  }, [setRef, textareaRef]);

  const prevValue = usePrevious(value);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;
    setValue(val);
    handleTextAreaChange && handleTextAreaChange(val);
  };

  const handleKeyUp: KeyboardEventHandler<HTMLTextAreaElement> = (evt) => {
    const target = evt.target as HTMLTextAreaElement;
    const val = target?.value;
    setValue(val);
  };

  const calculateTextAreaHeight = useCallback(() => {
    if (!textareaRef.current) return;

    const currentHeight = textareaRef.current.offsetHeight;

    // Max height of textarea should be 3/4 of screen size minus the heights of surround elements + small margin
    const MAX_HEIGHT = maxHeight ? maxHeight : window.innerHeight * 0.75 - 200;

    // We need to reset the height momentarily to get the correct scrollHeight for the textarea
    textareaRef.current.style.height = '0px';

    const scrollHeight = textareaRef.current.scrollHeight;

    // We then set the height directly, outside of the render loop
    // Trying to set this with state or a ref will product an incorrect value.
    // To fix a mobile behavior where the scroll height is bigger than it should,
    // only set the height if has any value or if deleted the whole input
    const hasValue = !!textareaRef.current.value?.length;
    const hasDeletedAll = prevValue?.length && !value.length;
    const shouldSetScroll = scrollHeight !== currentHeight && (hasValue || hasDeletedAll);

    if (!hasValue && initialHeight) {
      textareaRef.current.classList.remove('overflow-y-auto');
      textareaRef.current.classList.remove('global-scrollbar');
      textareaRef.current.style.height = `${initialHeight}px`;
      return;
    }

    let newHeight = shouldSetScroll ? scrollHeight : currentHeight;
    if (maxLength && value.length >= maxLength) {
      newHeight += 25; // add 25px to the current height when maximum character is reached to accomodate maxLength warning
    }
    if (newHeight > MAX_HEIGHT) {
      newHeight = MAX_HEIGHT;
      textareaRef.current.classList.add('overflow-y-auto');
      textareaRef.current.classList.add('global-scrollbar');
    } else {
      textareaRef.current.classList.remove('overflow-y-auto');
      textareaRef.current.classList.remove('global-scrollbar');
    }

    textareaRef.current.style.height = newHeight + 'px';
  }, [initialHeight, prevValue?.length, value.length, maxHeight]);

  useLayoutEffect(() => {
    calculateTextAreaHeight();
    truncatePlaceholderText(textareaRef, placeholder);
  }, [calculateTextAreaHeight, height, placeholder]);

  // truncate placeholder text if window width changes (e.g. resize event occured)
  useEffect(() => {
    truncatePlaceholderText(textareaRef, placeholder);
  }, [width, placeholder]);

  useEffect(() => {
    if (!textareaRef.current || !handleTextAreaResize) return;

    const resizeObserver = new ResizeObserver(() => {
      if (!textareaRef?.current?.offsetHeight) return;
      handleTextAreaResize(textareaRef?.current?.offsetHeight);
    });
    resizeObserver.observe(textareaRef.current);

    return () => resizeObserver.disconnect();
  }, [textareaRef, handleTextAreaResize]);

  const reachedMaxCharacters = value.length > 0 && value.length === maxLength;

  // the textarea is bigger when focused, so placeholder text truncation needs to be recalculated
  const handleFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
    truncatePlaceholderText(textareaRef, placeholder);
    htmlProps.onFocus && htmlProps.onFocus(e);
  };

  // the textarea is smaller when not focused, so placeholder text truncation needs to be recalculated
  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    truncatePlaceholderText(textareaRef, placeholder);
    htmlProps.onBlur && htmlProps.onBlur(e);
    registerRest && registerRest.onBlur && registerRest.onBlur(e);
  };

  return (
    <>
      <Tooltip
        className={cn({
          hidden: !tooltipContent,
        })}
        disabled={disabled}
        wrapperClasses="flex-1"
        wrapper={
          <label htmlFor={name} className={`label relative flex-1 ${labelClassname ?? ''}`}>
            <span
              className={cn({
                'subtitle-1 text-on-background-main block py-2': label,
                flex: isColor,
              })}
            >
              {isColor && (
                <div
                  className="my-auto mr-4 h-4 w-4 border-[1px]"
                  style={{ background: value }}
                ></div>
              )}
              {label} {required && label && <span className="text-attention-variant">(*)</span>}
            </span>
            {description && (
              <div className="body-2 text-on-control-main mb-2 mt-1 normal-case">{description}</div>
            )}
            <textarea
              {...registerRest}
              ref={(element: HTMLTextAreaElement) => {
                registerRef && registerRef(element);
                textareaRef.current = element;
                setValue(element?.value);
              }}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              className={cn(
                `body-1 border-control bg-surface text-on-surface placeholder:text-on-control-dimmed w-full resize-none overflow-hidden rounded-md border px-4 py-2 transition ${
                  className ?? ''
                }`,
                {
                  'pointer-events-none': disabled,
                  'pb-8': !!maxLength,
                  'focus:!border-control cursor-not-allowed': readOnly,
                  'hover:!border-secondary focus:!border-primary-cta': !readOnly,
                },
              )}
              {...htmlProps}
              onFocus={(e) => handleFocus(e)}
              onBlur={(e) => handleBlur(e)}
            ></textarea>
            {maxLength && (
              <div
                className={cn({
                  'body-2 bg-surface w-2/3 text-on-control-dimmed absolute bottom-0 left-1.5 mb-[5px] px-3 pb-2':
                    true,
                  'text-primary-cta': textareaRef?.current?.autofocus,
                  '!text-error-dark': reachedMaxCharacters,
                })}
              >
                 {value.length} / {maxLength} {reachedMaxCharacters ? (maxLengthWarning || 'Character limit reached') : ''}
              </div>
            )}
          </label>
        }
        arrowPosition="none"
      >
        {tooltipContent}
      </Tooltip>
      <div
        className={cn({
          'subtitle-2 text-attention-variant -mt-0.5 h-5 opacity-0 transition-opacity': true,
          '!opacity-100': !!errorMessage,
          hidden: !errorMessage,
        })}
      >
        {errorMessage}
      </div>
    </>
  );
};
