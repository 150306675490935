import { FC } from 'react';
import { IconProps } from '.';

export const IconUploadInChat: FC<IconProps> = ({ width = '97px', height = '87px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 97 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.6635 1.03436L46.5398 1.8399L8.05479 11.7644L4.93105 12.57C3.41483 12.9628 2.11634 13.941 1.32046 15.29C0.524584 16.639 0.296312 18.2487 0.685726 19.7658L14.2309 72.291C14.6237 73.8072 15.602 75.1057 16.951 75.9016C18.3 76.6974 19.9096 76.9257 21.4267 76.5363L21.4348 76.5342L66.1511 65.0028L66.1592 65.0007C67.6754 64.6079 68.9739 63.6297 69.7698 62.2806C70.5656 60.9316 70.7939 59.322 70.4045 57.8049L56.8593 5.27971C56.4665 3.76348 55.4883 2.46499 54.1393 1.66911C52.7903 0.873222 51.1806 0.644944 49.6635 1.03436Z"
        fill="currentColor"
        className="text-background"
      />
      <path
        d="M50.0883 2.68108L46.3885 3.63519L9.05555 13.2626L5.35576 14.2167C4.27602 14.4964 3.35133 15.193 2.78456 16.1537C2.2178 17.1144 2.05524 18.2606 2.33255 19.341L15.8777 71.8662C16.1575 72.9459 16.8541 73.8706 17.8148 74.4374C18.7754 75.0041 19.9217 75.1667 21.002 74.8894L21.0101 74.8873L65.7264 63.3559L65.7346 63.3538C66.8143 63.074 67.739 62.3774 68.3057 61.4167C68.8725 60.4561 69.035 59.3099 68.7577 58.2295L55.2125 5.70429C54.9328 4.62456 54.2362 3.69988 53.2755 3.13312C52.3149 2.56635 51.1687 2.40378 50.0883 2.68108Z"
        fill="currentColor"
        className="text-surface"
      />
      <path
        d="M47.2946 19.6901L24.6737 25.5236C24.421 25.5887 24.1529 25.5509 23.9281 25.4183C23.7034 25.2857 23.5406 25.0693 23.4754 24.8166C23.4103 24.564 23.4481 24.2958 23.5807 24.0711C23.7133 23.8463 23.9297 23.6835 24.1824 23.6183L46.8033 17.7849C47.0558 17.72 47.3238 17.758 47.5484 17.8906C47.7729 18.0232 47.9356 18.2395 48.0007 18.492C48.0658 18.7445 48.0281 19.0126 47.8956 19.2372C47.7632 19.4619 47.5471 19.6248 47.2946 19.6901Z"
        fill="currentColor"
        className="text-background"
      />
      <path
        d="M52.0531 21.8915L25.5025 28.7383C25.2499 28.8035 24.9817 28.7656 24.757 28.633C24.5323 28.5005 24.3694 28.284 24.3043 28.0314C24.2391 27.7787 24.277 27.5105 24.4096 27.2858C24.5421 27.0611 24.7586 26.8982 25.0112 26.8331L51.5617 19.9862C51.8144 19.9211 52.0826 19.959 52.3073 20.0916C52.532 20.2241 52.6949 20.4406 52.76 20.6932C52.8252 20.9459 52.7873 21.214 52.6547 21.4388C52.5221 21.6635 52.3057 21.8263 52.0531 21.8915Z"
        fill="currentColor"
        className="text-background"
      />
      <path
        d="M51.0105 34.0985L28.3896 39.932C28.2645 39.9643 28.1343 39.9716 28.0064 39.9535C27.8784 39.9354 27.7553 39.8924 27.6441 39.8267C27.5328 39.7611 27.4355 39.6741 27.3579 39.5709C27.2802 39.4677 27.2236 39.3501 27.1913 39.225C27.1591 39.0999 27.1518 38.9697 27.1698 38.8418C27.1879 38.7139 27.231 38.5908 27.2966 38.4795C27.3623 38.3682 27.4492 38.271 27.5524 38.1933C27.6557 38.1156 27.7732 38.059 27.8983 38.0268L50.5192 32.1933C50.6443 32.161 50.7745 32.1537 50.9025 32.1718C51.0304 32.1898 51.1535 32.2329 51.2648 32.2986C51.376 32.3642 51.4733 32.4512 51.551 32.5544C51.6286 32.6576 51.6852 32.7751 51.7175 32.9002C51.7498 33.0253 51.7571 33.1556 51.739 33.2835C51.7209 33.4114 51.6778 33.5345 51.6122 33.6458C51.5465 33.7571 51.4596 33.8543 51.3564 33.932C51.2531 34.0097 51.1356 34.0663 51.0105 34.0985Z"
        fill="currentColor"
        className="text-background"
      />
      <path
        d="M55.7689 36.3007L29.2184 43.1475C29.0933 43.1798 28.9631 43.1871 28.8352 43.169C28.7073 43.151 28.5841 43.1079 28.4729 43.0422C28.3616 42.9766 28.2644 42.8896 28.1867 42.7864C28.109 42.6832 28.0524 42.5657 28.0201 42.4406C27.9879 42.3155 27.9806 42.1852 27.9987 42.0573C28.0167 41.9294 28.0598 41.8063 28.1255 41.695C28.1911 41.5837 28.278 41.4865 28.3813 41.4088C28.4845 41.3311 28.602 41.2745 28.7271 41.2423L55.2776 34.3954C55.5303 34.3303 55.7985 34.3682 56.0232 34.5007C56.2479 34.6333 56.4108 34.8497 56.4759 35.1024C56.5411 35.355 56.5032 35.6232 56.3706 35.848C56.238 36.0727 56.0216 36.2355 55.7689 36.3007Z"
        fill="currentColor"
        className="text-background"
      />
      <path
        d="M54.7258 48.5067L32.1049 54.3402C31.8523 54.4052 31.5842 54.3672 31.3597 54.2346C31.1351 54.102 30.9724 53.8856 30.9072 53.6331C30.8421 53.3805 30.8799 53.1125 31.0124 52.8878C31.1448 52.6631 31.361 52.5003 31.6135 52.435L54.2345 46.6015C54.4871 46.5363 54.7553 46.5742 54.98 46.7068C55.2047 46.8394 55.3676 47.0558 55.4327 47.3084C55.4979 47.5611 55.46 47.8293 55.3274 48.054C55.1949 48.2787 54.9784 48.4416 54.7258 48.5067Z"
        fill="currentColor"
        className="text-background"
      />
      <path
        d="M59.4857 50.7089L32.9352 57.5557C32.8101 57.5881 32.6798 57.5955 32.5517 57.5775C32.4237 57.5595 32.3005 57.5165 32.1892 57.4509C32.0778 57.3852 31.9805 57.2983 31.9027 57.195C31.825 57.0917 31.7683 56.9742 31.7361 56.849C31.7038 56.7238 31.6965 56.5935 31.7146 56.4655C31.7327 56.3375 31.7759 56.2144 31.8416 56.103C31.9073 55.9917 31.9944 55.8945 32.0977 55.8168C32.201 55.7392 32.3187 55.6826 32.4439 55.6505L58.9944 48.8036C59.247 48.7385 59.5152 48.7764 59.7399 48.9089C59.9647 49.0415 60.1275 49.2579 60.1927 49.5106C60.2578 49.7632 60.2199 50.0314 60.0874 50.2562C59.9548 50.4809 59.7384 50.6437 59.4857 50.7089Z"
        fill="currentColor"
        className="text-background"
      />
      <path
        d="M20.8123 31.5054L12.807 33.5698C12.6859 33.6009 12.5573 33.5827 12.4496 33.5192C12.3419 33.4556 12.2638 33.3519 12.2324 33.2309L10.3948 26.105C10.3637 25.9839 10.3819 25.8553 10.4455 25.7476C10.509 25.6399 10.6127 25.5618 10.7338 25.5304L18.7391 23.466C18.8603 23.4349 18.9888 23.4531 19.0965 23.5167C19.2042 23.5802 19.2823 23.6839 19.3137 23.805L21.1513 30.9309C21.1824 31.052 21.1642 31.1805 21.1006 31.2882C21.0371 31.396 20.9334 31.4741 20.8123 31.5054Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M24.5282 45.9136L16.5229 47.978C16.4018 48.0091 16.2732 47.9909 16.1655 47.9274C16.0578 47.8638 15.9797 47.7601 15.9483 47.6391L14.1107 40.5132C14.0796 40.3921 14.0978 40.2635 14.1614 40.1558C14.225 40.0481 14.3286 39.97 14.4497 39.9386L22.455 37.8742C22.5762 37.8431 22.7047 37.8613 22.8124 37.9249C22.9201 37.9884 22.9982 38.0921 23.0296 38.2132L24.8672 45.3391C24.8983 45.4602 24.8801 45.5887 24.8165 45.6964C24.753 45.8042 24.6493 45.8823 24.5282 45.9136Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M28.2431 60.3218L20.2378 62.3863C20.1166 62.4173 19.9881 62.3991 19.8804 62.3356C19.7727 62.272 19.6945 62.1683 19.6632 62.0473L17.8256 54.9214C17.7945 54.8003 17.8127 54.6717 17.8762 54.564C17.9398 54.4563 18.0435 54.3782 18.1646 54.3468L26.1699 52.2824C26.291 52.2513 26.4195 52.2695 26.5273 52.3331C26.635 52.3966 26.7131 52.5003 26.7445 52.6214L28.5821 59.7473C28.6132 59.8684 28.5949 59.9969 28.5314 60.1047C28.4678 60.2124 28.3642 60.2905 28.2431 60.3218Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M80.8618 14.3486H34.6659C33.0996 14.3504 31.598 14.9734 30.4904 16.0809C29.3829 17.1884 28.7599 18.6901 28.7582 20.2564V74.5C28.7599 76.0663 29.3829 77.5679 30.4904 78.6754C31.598 79.7829 33.0996 80.4059 34.6659 80.4077H80.8618C82.4281 80.4059 83.9297 79.7829 85.0372 78.6754C86.1448 77.5679 86.7678 76.0663 86.7695 74.5V20.2564C86.7678 18.6901 86.1448 17.1884 85.0372 16.0809C83.9297 14.9734 82.4281 14.3504 80.8618 14.3486Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M80.8626 16.0498H34.6665C33.5512 16.0511 32.4818 16.4947 31.6931 17.2834C30.9044 18.0721 30.4608 19.1414 30.4595 20.2568V74.5004C30.4608 75.6158 30.9044 76.6852 31.6931 77.4739C32.4818 78.2626 33.5512 78.7062 34.6665 78.7075H80.8626C81.9779 78.7062 83.0473 78.2625 83.8359 77.4739C84.6246 76.6852 85.0683 75.6158 85.0695 74.5004V20.2568C85.0683 19.1414 84.6246 18.0721 83.8359 17.2834C83.0473 16.4947 81.9779 16.0511 80.8626 16.0498Z"
        fill="currentColor"
        className="text-surface"
      />
      <path
        d="M85.7154 87.0071C91.6714 87.0071 96.4997 82.1788 96.4997 76.2228C96.4997 70.2668 91.6714 65.4385 85.7154 65.4385C79.7594 65.4385 74.9311 70.2668 74.9311 76.2228C74.9311 82.1788 79.7594 87.0071 85.7154 87.0071Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M90.874 74.8169H87.123V71.0658C87.123 70.6928 86.9748 70.335 86.711 70.0712C86.4472 69.8074 86.0894 69.6592 85.7163 69.6592C85.3433 69.6592 84.9855 69.8074 84.7217 70.0712C84.4579 70.335 84.3097 70.6928 84.3097 71.0658V74.8169H80.5586C80.1856 74.8169 79.8278 74.9651 79.564 75.2289C79.3002 75.4927 79.152 75.8505 79.152 76.2235C79.152 76.5966 79.3002 76.9544 79.564 77.2182C79.8278 77.482 80.1856 77.6302 80.5586 77.6302H84.3097V81.3812C84.3097 81.7543 84.4579 82.1121 84.7217 82.3759C84.9855 82.6397 85.3433 82.7879 85.7163 82.7879C86.0894 82.7879 86.4472 82.6397 86.711 82.3759C86.9748 82.1121 87.123 81.7543 87.123 81.3812V77.6302H90.874C91.2471 77.6302 91.6049 77.482 91.8687 77.2182C92.1325 76.9544 92.2807 76.5966 92.2807 76.2235C92.2807 75.8505 92.1325 75.4927 91.8687 75.2289C91.6049 74.9651 91.2471 74.8169 90.874 74.8169Z"
        fill="currentColor"
        className="text-on-primary"
      />
      <path
        d="M73.9101 45.8563H50.5492C50.4199 45.8564 50.2918 45.8311 50.1723 45.7817C50.0529 45.7323 49.9443 45.6599 49.8528 45.5685C49.7613 45.4771 49.6888 45.3686 49.6393 45.2492C49.5898 45.1298 49.5643 45.0018 49.5643 44.8725C49.5643 44.7432 49.5898 44.6152 49.6393 44.4957C49.6888 44.3763 49.7613 44.2678 49.8528 44.1764C49.9443 44.0851 50.0529 44.0126 50.1723 43.9632C50.2918 43.9139 50.4199 43.8885 50.5492 43.8887H73.9101C74.1709 43.889 74.4208 43.9927 74.6051 44.1772C74.7893 44.3617 74.8928 44.6117 74.8928 44.8725C74.8928 45.1332 74.7893 45.3833 74.6051 45.5677C74.4208 45.7522 74.1709 45.856 73.9101 45.8563Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M77.9683 49.1766H50.5492C50.4199 49.1767 50.2918 49.1514 50.1723 49.102C50.0529 49.0526 49.9443 48.9802 49.8528 48.8888C49.7613 48.7974 49.6888 48.6889 49.6393 48.5695C49.5898 48.4501 49.5643 48.3221 49.5643 48.1928C49.5643 48.0635 49.5898 47.9355 49.6393 47.816C49.6888 47.6966 49.7613 47.5881 49.8528 47.4967C49.9443 47.4054 50.0529 47.3329 50.1723 47.2836C50.2918 47.2342 50.4199 47.2088 50.5492 47.209H77.9683C78.2292 47.209 78.4794 47.3126 78.6639 47.4971C78.8484 47.6816 78.9521 47.9319 78.9521 48.1928C78.9521 48.4537 78.8484 48.7039 78.6639 48.8884C78.4794 49.0729 78.2292 49.1766 77.9683 49.1766Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M73.9101 60.7361H50.5492C50.4199 60.7363 50.2918 60.7109 50.1723 60.6616C50.0529 60.6122 49.9443 60.5398 49.8528 60.4484C49.7613 60.357 49.6888 60.2485 49.6393 60.1291C49.5898 60.0097 49.5643 59.8816 49.5643 59.7523C49.5643 59.6231 49.5898 59.495 49.6393 59.3756C49.6888 59.2562 49.7613 59.1477 49.8528 59.0563C49.9443 58.9649 50.0529 58.8925 50.1723 58.8431C50.2918 58.7938 50.4199 58.7684 50.5492 58.7686H73.9101C74.1711 58.7686 74.4213 58.8722 74.6058 59.0567C74.7903 59.2412 74.8939 59.4914 74.8939 59.7523C74.8939 60.0133 74.7903 60.2635 74.6058 60.448C74.4213 60.6325 74.1711 60.7361 73.9101 60.7361Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M77.9683 64.0565H50.5492C50.4199 64.0566 50.2918 64.0313 50.1723 63.9819C50.0529 63.9325 49.9443 63.8601 49.8528 63.7687C49.7613 63.6773 49.6888 63.5688 49.6393 63.4494C49.5898 63.33 49.5643 63.202 49.5643 63.0727C49.5643 62.9434 49.5898 62.8154 49.6393 62.6959C49.6888 62.5765 49.7613 62.468 49.8528 62.3766C49.9443 62.2853 50.0529 62.2128 50.1723 62.1634C50.2918 62.1141 50.4199 62.0887 50.5492 62.0889H77.9683C78.0976 62.0887 78.2256 62.1141 78.3451 62.1634C78.4646 62.2128 78.5732 62.2853 78.6646 62.3766C78.7561 62.468 78.8287 62.5765 78.8782 62.6959C78.9277 62.8154 78.9532 62.9434 78.9532 63.0727C78.9532 63.202 78.9277 63.33 78.8782 63.4494C78.8287 63.5688 78.7561 63.6773 78.6646 63.7687C78.5732 63.8601 78.4646 63.9325 78.3451 63.9819C78.2256 64.0313 78.0976 64.0566 77.9683 64.0565Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M45.3157 50.6833H37.0484C36.9234 50.6832 36.8035 50.6334 36.715 50.545C36.6266 50.4565 36.5768 50.3366 36.5767 50.2116V42.8526C36.5768 42.7275 36.6266 42.6076 36.715 42.5192C36.8035 42.4307 36.9234 42.381 37.0484 42.3809H45.3157C45.4407 42.381 45.5606 42.4307 45.6491 42.5192C45.7375 42.6076 45.7873 42.7275 45.7874 42.8526V50.2116C45.7873 50.3366 45.7375 50.4565 45.6491 50.545C45.5606 50.6334 45.4407 50.6832 45.3157 50.6833Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M45.3157 65.5632H37.0484C36.9234 65.563 36.8035 65.5133 36.715 65.4249C36.6266 65.3364 36.5768 65.2165 36.5767 65.0915V57.7325C36.5768 57.6074 36.6266 57.4875 36.715 57.3991C36.8035 57.3106 36.9234 57.2609 37.0484 57.2607H45.3157C45.4407 57.2609 45.5606 57.3106 45.6491 57.3991C45.7375 57.4875 45.7873 57.6074 45.7874 57.7325V65.0915C45.7873 65.2165 45.7375 65.3364 45.6491 65.4249C45.5606 65.5133 45.4407 65.563 45.3157 65.5632Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M73.9363 29.2918H56.7132C56.4523 29.2918 56.202 29.1882 56.0175 29.0037C55.8331 28.8192 55.7294 28.5689 55.7294 28.308C55.7294 28.0471 55.8331 27.7969 56.0175 27.6124C56.202 27.4279 56.4523 27.3242 56.7132 27.3242H73.9363C74.1972 27.3242 74.4474 27.4279 74.6319 27.6124C74.8164 27.7969 74.9201 28.0471 74.9201 28.308C74.9201 28.5689 74.8164 28.8192 74.6319 29.0037C74.4474 29.1882 74.1972 29.2918 73.9363 29.2918Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M77.9944 32.6121H56.7132C56.584 32.6121 56.4561 32.5867 56.3367 32.5372C56.2174 32.4878 56.1089 32.4153 56.0175 32.324C55.9262 32.2326 55.8537 32.1242 55.8043 32.0048C55.7548 31.8854 55.7294 31.7575 55.7294 31.6283C55.7294 31.4991 55.7548 31.3712 55.8043 31.2518C55.8537 31.1325 55.9262 31.024 56.0175 30.9327C56.1089 30.8413 56.2174 30.7689 56.3367 30.7194C56.4561 30.67 56.584 30.6445 56.7132 30.6445H77.9944C78.2554 30.6445 78.5056 30.7482 78.6901 30.9327C78.8746 31.1172 78.9782 31.3674 78.9782 31.6283C78.9782 31.8892 78.8746 32.1395 78.6901 32.324C78.5056 32.5085 78.2554 32.6121 77.9944 32.6121Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M52.9011 36.6363H37.0229C36.8978 36.6361 36.7779 36.5864 36.6895 36.498C36.601 36.4095 36.5513 36.2896 36.5511 36.1646V23.7725C36.5513 23.6474 36.601 23.5275 36.6895 23.4391C36.7779 23.3507 36.8978 23.3009 37.0229 23.3008H52.9011C53.0261 23.3009 53.146 23.3507 53.2345 23.4391C53.3229 23.5275 53.3727 23.6474 53.3728 23.7725V36.1646C53.3727 36.2896 53.3229 36.4095 53.2345 36.498C53.146 36.5864 53.0261 36.6361 52.9011 36.6363Z"
        fill="currentColor"
        className="text-primary-cta"
      />
    </svg>
  );
};
