'use client';
import { IconCaret, IconFolderCloud, IconFolderSpace, IconFolderUnique } from '@unique/icons';
import { ColumnItemType, getMimeTypeVisuals } from '@unique/shared-library';
import cn from 'classnames';
import { FC } from 'react';
import { ColumnItemSkeleton } from './ColumnItemSkeleton';

export type ColumnItemProps = {
  label: string;
  additionalLabel?: string;
  mimeType?: string;
  href?: string;
  hasCaret?: boolean;
  isSelected?: boolean;
  isLoading?: boolean;
  type: ColumnItemType;
  handleClick?: (href?: string) => void;
};

export const ColumnItem: FC<ColumnItemProps> = ({
  label,
  additionalLabel,
  mimeType,
  href,
  hasCaret = false,
  isSelected = false,
  isLoading = false,
  type = ColumnItemType.File,
  handleClick,
}) => {
  const FileTypeIcon = getMimeTypeVisuals(mimeType ?? '', false);
  return (
    <>
      {isLoading ? (
        <ColumnItemSkeleton hasCaret={hasCaret} />
      ) : (
        <button
          onClick={() => handleClick && handleClick(href)}
          className={cn({
            'flex w-[100%] cursor-pointer flex-row items-center gap-[16px] py-[12px] pl-[24px] pr-[16px] transition':
              true,
            'hover:bg-background': typeof handleClick === 'function',
            '!cursor-default': typeof handleClick !== 'function',
            'bg-background-variant': !!isSelected,
          })}
        >
          <div>
            {type === ColumnItemType.Folder && <IconFolderUnique />}
            {type === ColumnItemType.SyncedFolder && <IconFolderCloud />}
            {type === ColumnItemType.SpaceFolder && <IconFolderSpace />}
            {type === ColumnItemType.File && <FileTypeIcon width="24px" />}
          </div>
          <div className="flex flex-1 flex-col items-start truncate">
            <p className="text-on-background-main flex-2 w-full grow overflow-hidden truncate text-left text-sm">
              {label}
            </p>
            {!!additionalLabel && (
              <p className="text-on-background-dimmed text-xs font-normal italic">
                {additionalLabel}
              </p>
            )}
          </div>

          {!!hasCaret && <div className="text-on-background-dimmed pr-[8px]">{<IconCaret />}</div>}
        </button>
      )}
    </>
  );
};
